<template>
  <div>
    <Row>
      <Col span="24">
        <FilterTable
          :trigger="triggerReset"
          name="vendors categories"
          :container="true"
          :fetch-items="this.vendorGetVendorcategories"
          namespace="vendorCategories"
          :columns="columns"
          :limit="30"
          :search-filters="['search']"
        />
      </Col>
      <Modal :title="`Vendor category #Id: ${target.id} -- ${target.name}`"
             width="80vh"
             v-model="modalEdit"
      >
        <h4>Vendor category Detail </h4>
        <br>
        <ul>
          <li><span class="title-span">Id : <span>{{ target.id }}</span></span></li>
          <li>
            <span class="title-span">Top category : <span>{{ target.topcategory }}</span></span>
          </li>
          <li><span class="title-span">Name: <span>{{ target.name }}</span></span></li>
          <li>
            <span class="title-span">Vigilance : <i :class="'offset compliance-id compliance-' + vendorVigilanceToLetter(target.vigilance_id)">
              {{ vendorVigilanceToLetter(target.vigilance_id) }}</i></span>
          </li>
          <li><span class="title-span">Is exempted : <span>{{ target.is_exempted }}</span></span></li>
          <Divider />
          <li><span class="title-span">Description:<br> <p>{{ target.description }}</p></span></li>
          <li><span class="title-span">Description admin :<br> <p>{{ target.descriptionadmin }}</p></span></li>
          <Divider />
          <li><span class="title-span">Created date: <span>{{ moment(target.created).format('LLL') }}</span></span></li>
          <li><span class="title-span">Modified date : <span>{{ moment(target.modified).format('LLL') }}</span></span></li>
        </ul>
        <br>
        <Button @click="isEditing = true"
                type="primary"
        >
          Edit descriptions
        </Button>
        <Divider
          v-if="isEditing"
        />     
        <Form ref="form"
              v-if="isEditing"
              :rules="ruleValidate"
              :model="form"
        >
          <FormItem prop="desc">
            <strong slot="label"> Category description </strong>
            <Input type="textarea"
                   v-model="form.description"
                   placeholder="Description"
            />
          </FormItem>
          <FormItem prop="desc">
            <strong slot="label">Category admin description</strong>
            <Input type="textarea"
                   v-model="form.descriptionadmin"
                   placeholder="Admin description"
            />
          </FormItem>
        </Form>
        <div slot="footer">
          <Button :type="isEditing ? 'warning' : 'primary'"
                  @click="editItem"
          >
            {{ isEditing ? 'Save' : 'Ok' }}
          </Button>
        </div>
      </Modal>
    </Row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'
import FilterTable from '@/components/ui/FilterTable'
import moment from 'moment'

export default {
  name: 'AdminCategories',
  components: {
    FilterTable
  },
  mixins: [
    pageConfig,
    tableColumn
  ],
  data () {
    return {
      riskColor:{
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      },
      moment: moment,
      modalEdit: false,
      isEditing: false,
      target: false,
      triggerReset: false,
      form: {
        description:'',
        descriptionadmin: ''
      },
      ruleValidate: {},
      /**
             * @note createColumn -> @/mixins/tableColumnn.js
             */
      columns: [
        this.createColumn(this.$t('id'), 'id', 'Categories.id',null),
        this.createColumn(this.$t('topcategory'), 'topcategory', 'Categories.topcategory',null),
        this.createColumn(this.$t('global.table.name'), 'name', 'Categories.name', null),
        this.createColumn(this.$t('vigilance'), 'vigilance_id', 'Categories.vigilance_id', row => {
          const color = this.riskColor[this.vendorVigilanceToLetter(row.vigilance_id)]
          return {
            tag: 'Tag',
            props: {color, type: 'dot'},
            content: this.vendorVigilanceToLetter(row.vigilance_id)
          }
        },140),
        {
          title: this.$t('global.filters.is_exempted'),
          sortable: 'custom',
          sortKey: 'Categories.is_exempted',
          render: (h, params) => {
            const render = h('Tag', {
              props: {
                color: 'blue'
              }
            }, 'Exempted')
            return params.row.is_exempted === 1 ? render : ''
          }
        },
        // this.createColumn(this.$t('description'), 'description', 'Categories.description', row => ({
        //   content: row.description.length > 15 ? '...' :row.description
        // })),
        // this.createColumn(this.$t('admin description'), 'descriptionadmin', 'Categories.descriptionadmin',row => ({
        //   content: row.descriptionadmin.length > 15 ? '...' :row.description
        // }), 'auto'),
        this.createColumn(this.$t('global.table.created'), 'created', 'Categories.created', 'date'),
        this.createColumn(this.$t('global.table.modified'), 'modified', 'Categories.modified', 'date'),
        {
          title: 'Action',
          key: 'action',
          width: 140,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.modalEdit = false
                    this.target = params.row
                    this.modalEdit = true
                    this.form.description = params.row.description
                    this.form.descriptionadmin = params.row.descriptionadmin
                  }
                }
              }, 'View or edit')
            ])
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      vendorGetVendorcategories: 'vendor/getVendorcategories',
      editVendorGetVendorcategories: 'vendor/editVendorcategories'

    }),
    editItem () {
      return this.editVendorGetVendorcategories({
        data: this.form,
        id: this.target.id
      }).then(r => {
        if (r.success) {
          this.modalEdit = false
          this.isEditing = false
          this.triggerReset = !this.triggerReset
          return this.$Notice.success({
            title: 'Success',
            desc: 'The Vendor category ' + this.target.name + 'has been saved succesfully' 
          })
        }
      })
    },
    actionCat(isEditing, category) {
      this.target = category
      this.modalEdit = !this.modalEdit
      this.isEditing = isEditing
      this.form.desc = category.description
      this.form.descriptionadmin = category.descriptionadmin
    },
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter'
    })
  }
}
</script>
<style scope lang="scss">
.compliance-id {
  left: inherit!important;
  margin-left: 5px!important;
}
.title-span {
  font-weight: 700;
  p {
      font-weight: normal;
  }
  span:nth-child(1) {
    font-weight: normal;
  }
}

</style>