var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FilterTable', {
    attrs: {
      "trigger": _vm.triggerReset,
      "name": "vendors categories",
      "container": true,
      "fetch-items": this.vendorGetVendorcategories,
      "namespace": "vendorCategories",
      "columns": _vm.columns,
      "limit": 30,
      "search-filters": ['search']
    }
  })], 1), _c('Modal', {
    attrs: {
      "title": "Vendor category #Id: ".concat(_vm.target.id, " -- ").concat(_vm.target.name),
      "width": "80vh"
    },
    model: {
      value: _vm.modalEdit,
      callback: function callback($$v) {
        _vm.modalEdit = $$v;
      },
      expression: "modalEdit"
    }
  }, [_c('h4', [_vm._v("Vendor category Detail ")]), _c('br'), _c('ul', [_c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Id : "), _c('span', [_vm._v(_vm._s(_vm.target.id))])])]), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Top category : "), _c('span', [_vm._v(_vm._s(_vm.target.topcategory))])])]), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Name: "), _c('span', [_vm._v(_vm._s(_vm.target.name))])])]), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Vigilance : "), _c('i', {
    class: 'offset compliance-id compliance-' + _vm.vendorVigilanceToLetter(_vm.target.vigilance_id)
  }, [_vm._v(" " + _vm._s(_vm.vendorVigilanceToLetter(_vm.target.vigilance_id)))])])]), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Is exempted : "), _c('span', [_vm._v(_vm._s(_vm.target.is_exempted))])])]), _c('Divider'), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Description:"), _c('br'), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.target.description))])])]), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Description admin :"), _c('br'), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.target.descriptionadmin))])])]), _c('Divider'), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Created date: "), _c('span', [_vm._v(_vm._s(_vm.moment(_vm.target.created).format('LLL')))])])]), _c('li', [_c('span', {
    staticClass: "title-span"
  }, [_vm._v("Modified date : "), _c('span', [_vm._v(_vm._s(_vm.moment(_vm.target.modified).format('LLL')))])])])], 1), _c('br'), _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditing = true;
      }
    }
  }, [_vm._v(" Edit descriptions ")]), _vm.isEditing ? _c('Divider') : _vm._e(), _vm.isEditing ? _c('Form', {
    ref: "form",
    attrs: {
      "rules": _vm.ruleValidate,
      "model": _vm.form
    }
  }, [_c('FormItem', {
    attrs: {
      "prop": "desc"
    }
  }, [_c('strong', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" Category description ")]), _c('Input', {
    attrs: {
      "type": "textarea",
      "placeholder": "Description"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "desc"
    }
  }, [_c('strong', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Category admin description")]), _c('Input', {
    attrs: {
      "type": "textarea",
      "placeholder": "Admin description"
    },
    model: {
      value: _vm.form.descriptionadmin,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "descriptionadmin", $$v);
      },
      expression: "form.descriptionadmin"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": _vm.isEditing ? 'warning' : 'primary'
    },
    on: {
      "click": _vm.editItem
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditing ? 'Save' : 'Ok') + " ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }